import React from 'react';
import { css } from '@emotion/core';
import { useTheme } from 'emotion-theming';
import Container from '@material-ui/core/Container';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuoteLeft } from '@fortawesome/pro-solid-svg-icons';

import H2 from './h2';

import mq from '../../../styles/mq';

import { ThemeType } from '../../../styles/theme';

const Quote: React.FC = () => {
  const { color } = useTheme<ThemeType>();

  return (
    <section
      css={css`
        background-color: ${color.primary};
        display: flex;
        align-items: center;
        margin: 100px 0;
        padding: 50px 0;
        position: relative;

        ${mq(`xs`)} {
          padding: 100px 0;
        }
      `}
    >
      <div
        css={css`
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          font-size: 250px;
          color: ${color.white};
          opacity: 0.25;

          ${mq(`md`)} {
            font-size: 300px;
          }

          ${mq(`lg`)} {
            font-size: 250px;
          }
        `}
      >
        <FontAwesomeIcon icon={faQuoteLeft} />
      </div>
      <Container
        css={css`
          z-index: 2;
        `}
      >
        <H2
          css={css`
            color: ${color.warning};
            text-align: center !important;
          `}
        >
          Nous avons ainsi choisi une micro- brasserie à finalité social et 100%
          bio.
        </H2>
      </Container>
    </section>
  );
};

export default Quote;
