import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { useTheme } from 'emotion-theming';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import Container from '@material-ui/core/Container';

import Layout from '../components/layout';
import Steps from '../components/pages/about/steps';
import Quote from '../components/pages/about/quote';

import mq from '../styles/mq';

import { ImageType } from '../types/image';
import { ThemeType } from '../styles/theme';
import SEO from '../components/helpers/seo';

const Grid = styled.div`
  ${mq(`md`)} {
    display: flex;
    margin: 0 -30px;
  }
`;

const Column = styled.div`
  margin-bottom: 50px;

  ${mq(`md`)} {
    flex: 0 0 50%;
    max-width: 50%;
    margin-bottom: 0;
    padding: 0 30px;
  }

  &:last-of-type {
    margin-bottom: 0;
  }
`;

export const query = graphql`
  {
    team: file(name: { eq: "team" }) {
      childImageSharp {
        fluid(maxWidth: 1440, maxHeight: 470, fit: COVER, cropFocus: CENTER) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

interface AboutProps {
  data: {
    team: ImageType;
  };
}

const About: React.FC<AboutProps> = ({ data: { team } }) => {
  const { color } = useTheme<ThemeType>();

  return (
    <Layout footer>
      <SEO
        title="L'histoire de la 3D"
        description="Les 3 amis ont voulu retrouver le gout de la bière de leur jeunesse."
      />
      <section
        css={css`
          padding: 100px 0;

          ${mq(`md`)} {
            padding: 250px 0;
          }

          p {
            font-size: 16px;
            color: ${color.accent};
            font-weight: 600;
            text-align: justify;

            ${mq(`xl`)} {
              font-size: 20px;
            }
          }
        `}
      >
        <Container>
          <h1
            css={css`
              color: ${color.accent};
              font-size: 30px;
              font-weight: 900;
              margin-bottom: 50px;
              text-align: center;

              ${mq(`xs`)} {
                font-size: 40px;
              }

              ${mq(`sm`)} {
                font-size: 50px;
              }

              ${mq(`md`)} {
                font-size: 60px;
                max-width: 820px;
                text-align: left;
              }
            `}
          >
            Les 3 amis ont voulu retrouver le gout de la bière de leur jeunesse.
          </h1>
          <figure
            css={css`
              margin-bottom: 50px;
            `}
          >
            <Img fluid={team.childImageSharp.fluid} />
          </figure>
        </Container>
        <Container>
          <Grid>
            <Column>
              <p>
                Un peu comme un pari ou un défi. Trois chefs d’entreprises
                nilois, Bruno Degueldre (52 ans), Xavier (46) et Olivier (43)
                Delforge, viennent de lancer la 3D. C’est une bière artisanale
                aux initiales de la première lettre de leurs noms de familles.
                Si les micro-brasseries sont dans l’air du temps, leur objectif
                n’est nullement commercial.
              </p>
            </Column>
            <Column>
              <p>
                « En discutant ensemble, nous évoquions le goût de la
                Vieux-Temps lorsqu’elle était brassée à Mont-Saint-Guibert. La
                nostalgie de celle-ci restait encore bien présente ». Lors des
                journées entreprises, ils sont allés visiter plusieurs
                brasseries. Et ils se sont dit qu’ils essaieraient bien de faire
                le test de retrouver une bière au goût de leur jeunesse. La
                période de confinement leur a permis de concrétiser leur idée
                folle.
              </p>
            </Column>
          </Grid>
        </Container>
        <Steps />
        <Container>
          <Grid>
            <Column>
              <p>
                « Nous avons ainsi choisi une micro-brasserie à finalité social
                et 100% bio, soit la brasserie du Renard à Grez-Doiceau. Nous
                voulions seulement brasser 10 hectolitres pour nous et quelques
                amis. Ce fut le buzz et… le rush. Tout le monde en voulait… Du
                coup, nous avons décidé de créer une asbl ». Ce fut ensuite 10
                hectolitres supplémentaires. Aujourd’hui, en quatre mois, ils
                frôlent les 60 hectolitres.
              </p>
            </Column>
            <Column>
              <p>
                La 3D est un mélange entre la Vieux-Temps et la Leffe blonde.
                Produite avec du houblon bio, elle est une bière de soif ambrée,
                cuivrée avec une pointe d’amertume et un côté épicé et fruité.
                Une bière d’ « amis » qu’on peut boire à la bouteille ou au
                verre même s’ils n’en ont pas encore à leur logo. 6° d’alcool.
                Bref, pour tous les âges, toutes les générations et tous les
                goûts.
              </p>
            </Column>
          </Grid>
        </Container>
        <Quote />
        <Container>
          <Grid>
            <Column>
              <p>
                Leurs objectifs ? « Nous n’en savons rien. Nous avons voulu
                faire cette bière pour nous amuser », Bruno Degueldre étant
                patron d’une fiduciaire bien connue, tandis que les frères
                Delforge dirigent une entreprise de construction. « Aujourd’hui,
                nous sommes dépassés par l’engouement chez nos amis, nos
                connaissances, mais aussi dans les bistrots du village de
                l’entité walhinoise. Lundi dernier, nous avons écoulé dix
                hectolitres, soit l’équivalent de 3.000 bouteilles de 33cl, en
                une journée ! Début septembre, nous mettrons encore sur le
                marché 20 hectolitres.
              </p>
            </Column>
            <Column>
              <p>
                Le circuit court était aujourd’hui rentré dans les mœurs, leur
                bière est aussi disponible dans des clubs de football du coin,
                au château de Walhain, à l’Intermarché de la N4. Elle devrait
                l’être dans d’autres supermarchés de la région proche. « La
                commune nous soutient. De notre côté, nous aimons soutenir les
                commerces locaux afin d’être partenaires et de produire avec eux
                ».
              </p>
            </Column>
          </Grid>
          <p
            css={css`
              margin-top: 50px;
            `}
          >
            Suite à une demande, ils ont également accepté qu’un fromage à la 3D
            soit produit par une artisane locale. Les trois compères ont
            également reçu une demande pour des boulettes et… des pralines à la
            3D.
          </p>
        </Container>
      </section>
    </Layout>
  );
};

export default About;
