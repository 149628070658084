import styled from '@emotion/styled';

import mq from '../../../styles/mq';

import { ThemeType } from '../../../styles/theme';

interface H2Props {
  theme: ThemeType;
}

const H2 = styled.h2<H2Props>`
  color: ${({ theme }) => theme.color.white};
  font-size: 24px;
  font-weight: 900;
  text-align: center;

  ${mq(`xs`)} {
    font-size: 30px;
  }

  ${mq(`sm`)} {
    font-size: 40px;
  }

  ${mq(`md`)} {
    font-size: 50px;
    text-align: left;
  }
`;

export default H2;
