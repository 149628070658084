import React, { useCallback } from 'react';
import { css } from '@emotion/core';
import { useTheme } from 'emotion-theming';
import Container from '@material-ui/core/Container';

import H2 from './h2';

import mq from '../../../styles/mq';

import { ThemeType } from '../../../styles/theme';

type StepType = {
  title: string;
  content: string;
};

const steps: StepType[] = [
  {
    title: `Naturellement artisanale`,
    content: `La 3D est brassée selon un procédé artisanal qui leur permet de développer tous les arômes qui les rendent complexes et uniques, notamment par une garde longue de plus de 2 mois. Nous garantissons le caractère naturel de nos bières puisque nous n’utilisons aucun colorant, ni conservateur ni aucun autre produit de synthèse dans l’élaboration de nos bières. De plus, nos bières ne sont pas filtrées, pasteurisées et centrifugées, ce qui leur permet de garder tous leurs arômes. Le CO2 qui fait pétiller nos bières est obtenu par refermentation en bouteille.`,
  },
  {
    title: `Fruitée ou épicée mais non sucrée`,
    content: `Ni sucrée ni acide, où le fruit apporte des arômes subtils qui étoffent ceux apportés par le malt, le houblon et la levure. Pour cela, nous utilisons lors du brassage de nos bières, des fruits frais que nous incorporons à nos autres ingrédients en début de fermentation ce qui permet à la levure de magnifier les arômes qu’ils apportent.`,
  },
  {
    title: `Bière BIO`,
    content: `Certifiée « bio » par Certisys , à partir de matières premières produites localement et achetées en direct aux producteurs, artisanales, aux épices ou aux fruits frais, sans sucres ajoutés, solidaires, vendues en filières courtes, choisies avec les coopérateurs, durables et qui ont du sens.`,
  },
  {
    title: `« D’antan »`,
    content: `Lorsque vous la gouterez, plongez votre nez dans votre verre, elle dégage un parfum fruité mais épicé avec une pointe d’amertume qui rappellera à certains, une bière au goût d’antan. C’est un petit « retour vers le passé ». Pour les plus connaisseurs d’entre vous, elle vous rappellera certainement un célèbre mélange de notre belle Province !`,
  },
];

const Steps: React.FC = () => {
  const { color } = useTheme<ThemeType>();

  const renderSteps = useCallback(
    () => (
      <ul
        css={css`
          ${mq(`md`)} {
            display: flex;
            flex-wrap: wrap;
            margin: 0 -30px;
          }
        `}
      >
        {steps.map((step, key) => (
          <li
            key={key}
            css={css`
              margin-bottom: 50px;

              ${mq(`md`)} {
                flex: 0 0 50%;
                max-width: 50%;
                margin-bottom: 100px;
                padding: 0 30px;

                &:nth-of-type(3) {
                  margin-bottom: 0;
                }
              }

              &:last-of-type {
                margin-bottom: 0;
              }
            `}
          >
            <article
              css={css`
                text-align: center;

                ${mq(`md`)} {
                  display: flex;
                  text-align: left;
                }
              `}
            >
              <div
                css={css`
                  color: ${color.warning};
                  font-size: 40px;
                  font-weight: 900;
                  flex: 0 0 70px;
                  padding-top: 5px;

                  ${mq(`xs`)} {
                    font-size: 45px;
                    flex: 0 0 80px;
                  }

                  ${mq(`sm`)} {
                    font-size: 50px;
                  }

                  ${mq(`xl`)} {
                    font-size: 70px;
                    flex: 0 0 120px;
                    padding-top: 0;
                  }
                `}
              >
                0{key + 1}
              </div>
              <div
                css={css`
                  padding-top: 20px;
                `}
              >
                <h3
                  css={css`
                    color: ${color.white};
                    font-size: 20px;
                    font-weight: 900;
                    margin-bottom: 20px;

                    ${mq(`xs`)} {
                      font-size: 24px;
                    }

                    ${mq(`sm`)} {
                      font-size: 30px;
                    }

                    ${mq(`lg`)} {
                      margin-bottom: 30px;
                    }

                    ${mq(`xl`)} {
                      font-size: 40px;
                      margin-bottom: 25px;
                    }
                  `}
                >
                  {step.title}
                </h3>
                <p
                  css={css`
                    color: ${color.white} !important;
                    font-size: 16px !important;
                    font-weight: 600;
                    text-align: center !important;

                    ${mq(`sm`)} {
                      max-width: 80%;
                      margin: auto;
                    }

                    ${mq(`md`)} {
                      max-width: initial;
                      margin: 0;
                      text-align: justify !important;
                    }

                    ${mq(`xl`)} {
                      font-size: 20px !important;
                    }
                  `}
                >
                  {step.content}
                </p>
              </div>
            </article>
          </li>
        ))}
      </ul>
    ),
    []
  );

  return (
    <section
      css={css`
        background-color: ${color.primary};
        margin: 100px 0;
        padding: 50px 0;

        ${mq(`xs`)} {
          padding: 100px 0;
        }
      `}
    >
      <Container>
        <H2
          css={css`
            margin-bottom: 50px;
            max-width: 820px;

            ${mq(`xs`)} {
              margin-bottom: 75px;
            }
          `}
        >
          Un certain mélange entre la vieux temps et la Leffe.
        </H2>
        {renderSteps()}
      </Container>
    </section>
  );
};

export default Steps;
